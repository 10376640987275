export const questionList = [
    {
        subtitle: "Introduction",
        question: "Do you want to find out if you're at risk for hereditary breast and ovarian cancer (HBOC)?",
        value: 1,
    },
    {
        subtitle: "Family Cancer History",
        question: "Do you have a strong family health history of breast and ovarian cancers?",
        value: 2,
    },
    {
        subtitle: "Ancestry and Family History",
        question: "Do you have a moderate family health history of breast and ovarian cancers and are of Ashkenazi or Eastern European Jewish ancestry?",
        value: 3,
    },
    {
        subtitle: "Personal Cancer History",
        question: "Do you have a personal history of breast cancer and meet specific criteria, such as being diagnosed before age 50 and having a family health history of cancer?",
        value: 4,
    },
    {
        subtitle: "Genetic Mutation in Family",
        question: "Do you have a known BRCA1, BRCA2, or other inherited mutation in your family?",
        value: 5,
    },
    {
        subtitle: "Personal Cancer History",
        question: "Do you have a personal history of ovarian, fallopian tube, or primary peritoneal cancer?",
        value: 6,
    },
];

export const testResult = [
    {
        type: "BRCA1 / BRCA2",
        values: [1, 2, 3, 4, 5]
    },
    {
        type: "TCF7L2 / SLC30A8",
        values: [9]
    },
    {
        type: "LRRK2 / GBA",
        values: [9]
    },
    {
        type: "APOE",
        values: [9]
    },
    {
        type: "APOL1",
        values: [9]
    },
];
