<template>
  <div class="dimbox agreement find-my-gene-modal">
    <div class="header">
      <button class="btn" @click="close">
        <img src="@/assets/images/close-black.svg" alt="close" />
      </button>
    </div>
    <div class="legalContent question-box">
      <div v-if="!revealResult && !allNotSure">
        <h1>Your Genetic Health Check</h1>
        <h5>{{ questionList[questionNumber].subtitle }}</h5>
        <h4>{{ questionList[questionNumber].question }}</h4>
        <div v-html="reference"></div>
        <div class="buttonWrap flexB">
          <div class="radio-buttons">
            <input 
              type="radio" 
              name="boolean" 
              value="yes" 
              id="yes" 
              @change="handleChange"
              @click="handleClick('yes')"
              v-model="yesOrNo" 
            />
            <label for="yes" :class="{ selected: yesOrNo === 'yes' }">Yes</label>
  
            <input 
              type="radio" 
              name="boolean" 
              value="no" 
              id="no" 
              @change="handleChange"
              @click="handleClick('no')"
              v-model="yesOrNo" 
            />
            <label for="no" :class="{ selected: yesOrNo === 'no' }">No</label>
            <input 
              type="radio" 
              name="boolean" 
              value="not-sure" 
              id="not-sure" 
              @change="handleChange"
              @click="handleClick('not-sure')"
              v-model="yesOrNo" 
            />
            <label for="not-sure" :class="{ selected: yesOrNo === 'not-sure' }">Not Sure</label>
          </div>
        </div>
        <div class="buttonWrap flexB">
          <button class="next-button" v-if="!isQuestionDone" :disabled="nextDisabled" @click="next">Next</button>
          <button class="next-button" v-if="isQuestionDone" :disabled="nextDisabled" @click="submit(false)">Submit</button>
        </div>
      </div>

      <div v-if="revealResult && relatedGenes.length > 0" class="test-result">
        <h1>Genes to Watch For</h1>
        <div
          class="specialty__item"
          v-for="(specialty, index) in relatedGenes"
          :key="index"
          :id="`${specialty.gene}-id`"
        >
          <div class="thum">
            <a :href="`/list?searchWord=${specialty.gene.split('/')[0].trim()}&addressWord=san%20francisco`">
              <img
                :src="require(`@/assets/images/specialties/${specialty.img}`)"
                alt="specialty image"
              />
            </a>
          </div>
          <div class="desc">
            <a :href="`/list?searchWord=${specialty.gene.split('/')[0].trim()}&addressWord=san%20francisco`">
              <h3>{{ specialty.gene }}</h3>
            </a>
            <p class="nm" v-html="specialty.name"></p>
          </div>
          <p v-if="resultSecond">Tap the icon to explore more detailed search results</p>

          <div class="desc" v-if="resultFirst">
            <p class="nm">
              Disclosure: This survey is for educational purposes only and does not provide any medical diagnosis, advice, or treatment. For any medical concerns, diagnoses, advice, or treatments, you must consult your genetic counselor or another qualified healthcare provider. By proceeding, you acknowledge and agree to this disclaimer. Please check "Yes" to confirm your understanding and continue to the next page.
            </p>
            <div class="buttonWrap flexB">
              <button class="next-button" @click="seeResult">Yes</button>
            </div>
          </div>
          <div class="desc" v-if="resultSecond">
            <p class="nm">
              Based on the information you provided, you might be at risk for hereditary breast and ovarian cancer (HBOC). You may want to consider genetic testing to identify gene alterations related to HBOC. Since we do not provide any medical advice, diagnosis, or treatment, you must consult your genetic counselor or another qualified healthcare provider. By clicking THE ICON ABOVE and entering your zip code, you can find medical professionals in your area who may help you.
            </p>
            <br><br>
            <p class="nm">Reference: <a href="https://www.cdc.gov/breast-ovarian-cancer-hereditary/testing/index.html">https://www.cdc.gov/breast-ovarian-cancer-hereditary/testing/index.html</a></p>
          </div>
        </div>
      </div>

      <div v-if="revealResult && !relatedGenes.length" class="test-result">
        <h1>All Clear!</h1>
        <p class="no-result">Great news! Based on the information you provided, we found no known risk or suggestion for HBOC. However, please note that we do not offer medical advice, diagnosis, or treatment. It's essential to consult with your genetic counselor or another qualified healthcare provider for any medical decisions or diagnoses. By proceeding to the next page and entering your zip code, you can locate medical professionals in your area who can provide further information if you wish to learn more details.</p>
        <p class="no-result">However, it is important to stay informed about genetic health. Explore more about genetic information and conditions below.</p>
        <a @click="close" class="more-link" href="#main-specialties">Discover More Genetic Conditions</a>
        <p class="nm">Reference: <a href="https://www.cdc.gov/breast-ovarian-cancer-hereditary/testing/index.html">https://www.cdc.gov/breast-ovarian-cancer-hereditary/testing/index.html</a></p>
      </div>

      <div v-if="allNotSure" class="test-result">
        <h1>Unable to Determine</h1>
        <p class="no-result">It seems you've selected "Not Sure" for all the questions. Unfortunately, this makes it difficult to assess your genetic health risks at this time.</p>
        <p class="no-result">We recommend reviewing your family and personal health history, and consulting with a healthcare professional if you have any concerns about your genetic health.</p>
        <a @click="close" class="more-link" href="#main-specialties">Learn More About Genetic Health</a>
      </div>
    </div>
  </div>
</template>
<script>
import { questionList, testResult } from "@/assets/data/questions.js";
import gtag from "ga-gtag";

export default {
  mounted() {
    this.sendGtagEvent(
      'Find my gene - test start',    // eventName
      'find_my_gene-start', // eventCategory
      'render-find-my-gene',                // eventAction
      'find-my-gene-rendered'      // eventLabel
    );
  },
  data() {
    return {
      answers: [],
      questionNumber: 0,
      yesOrNo: null,
      nextDisabled: true,
      revealResult: false,
      allNotSure: false,
      resultFirst: true,
      resultSecond: false,
      relatedGenes: [],
      specialties: [
        {
          name: "Hereditary<br> Breast Cancer",
          gene: "BRCA1 / BRCA2",
          img: "specialty1.png",
        },
        {
          name: "Type 2 Diabetes",
          gene: "TCF7L2 / SLC30A8",
          img: "specialty3.png",
        },
        {
          name: "Chronic<br> Kidney Disease",
          gene: "APOL1",
          img: "specialty6.png",
        },
        {
          name: "Late-Onset<br> Alzheimer's Disease",
          gene: "APOE",
          img: "specialty11.png",
        },
        {
          name: "Parkinson's Disease",
          gene: "LRRK2 / GBA",
          img: "specialty12.png",
        },
      ],
      questionList,
      testResult
    };
  },
  computed: {
    isQuestionDone() {
      return this.questionNumber === this.questionList.length - 1;
    },
    reference() {
      const reference = this.questionList[this.questionNumber].reference;
      if (reference) {
        return `<a href="${reference.link}" target="_blank">${reference.title}</a>`;
      }
      return '';
    },
  },
  methods: {
    sendGtagEvent(eventName, eventCategory, eventAction, eventLabel) {
      if (typeof gtag === 'function') {
        gtag("event", eventName, {
          event_category: eventCategory,
          event_action: eventAction,
          event_label: eventLabel,
        });
      } else {
        console.error("gtag is not defined");
      }
    },
    seeResult() {
      this.resultFirst = false;
      this.resultSecond = true;
    },
    renderReference(title, link) {
      if(!title || !link) {
        return null;
      }
      return <a href={link} target="_blank">{title}</a>
    },
    close() {
      this.$emit("close");
    },
    submit(noTest = false) {
      if(noTest) {
        this.close();
      }
      const resultArray = [...testResult];
      const answersList = [...this.answers];

      console.log("answersList", answersList)

      // 모든 답변이 'not-sure'인 경우 처리
      if (answersList.every(answer => answer === 'not-sure')) {
        this.allNotSure = true;
        return;
      }

      // 특정 값이 포함되었는지 확인하는 함수
      function isSubset(subset, set) {
        return subset.some(element => set.includes(element));
      }

      // 결과 배열을 순회하면서 적합한 유전자를 찾음
      resultArray.forEach(elem => {
        const isQualified = isSubset(elem.values, answersList);
        if (isQualified) {
          // 이미 추가된 유전자가 없을 때만 관련 유전자를 추가
          const targetedGeneObj = this.specialties.find(specialty => specialty.gene === elem.type);
          if (targetedGeneObj && !this.relatedGenes.includes(targetedGeneObj)) {
            this.relatedGenes.push(targetedGeneObj);
          }
        }
      });

      this.sendGtagEvent(
        'Find my gene - test done',    // eventName
        'find_my_gene-done', // eventCategory
        'submit-find-my-gene',                // eventAction
        'find-my-gene-submit'      // eventLabel
      );

      // 결과 화면 표시
      this.revealResult = true;
    },

    next() {
      if(this.questionNumber === 0 && this.yesOrNo === 'no') {
        this.submit(true);
        return;
      }
      if(this.yesOrNo === 'yes') {
        this.answers.push(this.questionNumber);
      }
      if(this.yesOrNo === 'not-sure') {
        this.answers.push('not-sure');
      }
      if(this.questionNumber === 5 && !!this.yesOrNo) {
        this.submit();
        return;
      }
      this.yesOrNo = null;
      this.nextDisabled = true;
      this.questionNumber = this.questionNumber + 1;
    },
    handleChange(event) {
      this.yesOrNo = event.target.value;
      this.nextDisabled = false;
    },
    handleClick(value) {
      if (this.yesOrNo === (value === 'yes')) {
        this.yesOrNo = null;  // Reset to null to force change detection
      }
      this.yesOrNo = value;
      this.nextDisabled = false;
    }
  },
};
</script>
