<template>
  <div class="dimbox agreement">
    <div class="header">
      <button class="btn" @click="close">
        <img src="@/assets/images/close-black.svg" alt="close" />
      </button>
    </div>
    <div class="legalContent">
      <h3>TERMS OF USE</h3>
      <h4>Effective Date: 2022-05-26</h4>

      <p>
        The content in this document contains an important information that you
        as a user of our Services should consider. This Agreement constitutes a
        contract between you and us, Doctagen.com, that governs your use of the
        Services, meaning that you are able to use any part of our Services only
        by agreeing to this Terms and Conditions as accessed. You can freely
        choose not to agree to this agreement, yet it indicates that you will
        not be able to use our Services. This agreement will continue to be
        effective after your use of the Services is terminated for any reason
        regarding to your prior use.
      </p>
      <br />
      <p>
        Our goal is to keep improving the quality of our Services, thus the
        content in these Terms may change along the Service updates, whereas we
        reserve the right to make such changes at any time. We may provide a
        notice when it happens, and any use of the Services after the Terms
        update means that you agree to all the changes.
      </p>
      <br />
      <p>
        Terms listed below shall govern the authorized use of our Services by
        you or your company from Doctagen.com. We do our best to provide high
        quality Services, yet we make no guarantees to any of the content or
        information provided through our platform. Under any circumstances or
        events Doctagen.com will NOT be liable for any decisions or actions
        taken by you as a user.
      </p>
      <br />

      <b>NO MEANS FOR MEDICAL ADVICE</b>
      <p>
        Doctagen.com is not a referral service provider. You should by no means
        use our Services as any professional medical advice, diagnosis,
        treatment or emergency intervention. Reliance on any information
        provided through our Services is solely at your own risk. Doctagen.com
        is not responsible for any result or effect caused by the use of our
        Services from the users. The contents from our Services are for
        informational purposes only. Information and contents of our Services
        may be changed anytime as aforementioned note.
      </p>
      <br />

      <b>ACCOUNT CREDENTIALS AND RESPONSIBILITIES</b>
      <p>
        You, as a user, can freely create an account at Doctagen.com, but should
        do so with accurate registration information, and keep your account
        information (email address and password, etc.) in private and secure
        manner. If you choose to connect using the third party Services,
        including but not limited to Google LLC, it means you are giving us the
        permission to access and use your information and to store your
        credentials as permitted by such third party service. You should contact
        us for support when there is any compromise occurred to your account
        credentials.
      </p>
      <br />

      <b>DATA AND PRIVACY</b>
      <p>
        We inform you that there are lists of user's personal information and
        data that can be gathered directly or indirectly in connection with our
        Services provided (more described in our Privacy Policy document). By
        agreeing to our Terms, you acknowledge that you accept the practices and
        policy in the Privacy Policy document, and consent that we will collect,
        use, and share the data in the ways described in the dcxument details.
      </p>
      <br />
      <b>DISCLAIMER</b>
      <p>
        Our service is to provide great experience to our users. You do
        acknowledge that we only provide the information and services on an
        "AS-IS" basis, we make no representations or warranties as to the
        contents and services provided. You may only use our Services in lawful
        and non-commercial purposes. We may take any legal actions and measures
        to prevent violations and enforce the Terms. We reserve the right to
        terminate/suspend/deactivate your account when a violation of our Terms
        and Agreement is found.
      </p>
      <br />
      <div class="agreeBox">
        <input
          type="checkbox"
          id="accept"
          v-model="personDataCollectAgreeFlag"
        />
        <label for="accept">I have read and accept this terms.</label>
      </div>
      <div class="buttonWrap flexB">
        <button @click="submit">Accept</button>
        <button @click="close">Cancel</button>
      </div>
    </div>
  </div>
</template>
<script>
import { updateUser } from "@/api/index";
import { mapState } from "vuex";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      //CALL_CENTER: CALL_CENTER,
      //compareWord: "",
      // searchWord: "",
      personDataCollectAgreeFlag: false,
    };
  },
  computed: {
    ...mapState(["isLogin", "userId"]),
  },
  methods: {
    // handleList() {
    //   this.$router.push("/list");
    // },
    close() {
      this.$emit("close");
    },
    submit() {
      // console.log( "personDataCollectAgreeFlag", this.personDataCollectAgreeFlag );
      if (!this.personDataCollectAgreeFlag) {
        // this.close();
        return alert(
          "Please click the check box to confirm that you agree to the Terms and Privacy Policy."
        );
      }
      if (this.isLogin == true) {
        let data = {
          userTerms: true,
        };
        updateUser(data).then((res) => {
          if (res.data.status == 200) {
            //console.log("updateUser", res.data);
            this.$emit("submit");
          } else {
            return alert(res.data.message);
          }
        });
      } else {
        Cookies.set(
          "DoctagenPersonDataCollectAgreeFlag",
          this.personDataCollectAgreeFlag,
          { expires: 30 }
        );
        this.$emit("submit");
        //this.$emit("submit", this.searchWord, this.compareWord);
      }
    },
  },
};
</script>
