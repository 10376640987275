<template>
  <main id="main">
    <section class="main-search">
      <div class="main-search__inner">
        <div
          class="search_title"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <h1>
            The Easiest Way to<br />
            Find <span class="main_color">Doctors</span> for<br />
            Your Genetic Problems
          </h1>
          <div class="search">
            <div class="type_search">
              <div class="input">
                <input
                  class="gene"
                  type="text"
                  placeholder="Type your gene (eg. BRCA1, APOE)"
                  v-model="searchWord"
                  @keyup.enter="handleAgreement"
                />
              </div>
            </div>
            <div class="loc_search">
              <div class="input">
                <input
                  class="place"
                  type="text"
                  placeholder="San Francisco"
                  v-model="addressWord"
                  @keyup.enter="handleAgreement"
                />
              </div>
            </div>
            <button type="button" class="btn_search" @click="handleAgreement">
              <span>Search</span>
            </button>
          </div>
          <button type="button" class="mo-btn_search" @click="handleAgreement">
            <span>Search</span>
          </button>
        </div>

        <div class="find-gene-type" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
          <button
            type="button"
            class="mo-btn_search"
            @click="openFindMyGeneModal"
          >
            <span>Worried About Breast or Ovarian Cancer?<br /><b>Check Your Personal Risk Now!</b></span>
          </button>
        </div>

        <div
          class="near_doctor"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="1000"
        >
          <h1 class="main_tit">
            <span class="main_color">Click to find</span> a doctor near you
          </h1>
          <div class="near__list">
            <div
              v-for="(category, index) in categories"
              :key="index"
              class="near__item"
              @click="() => selectCategory(category.searchTerm, true)"
              :id="`${category.gene}-id`"
            >
              <span class="btn_href"></span>
              <div
                class="disease"
                @click="() => clickFromWhere(category, true)"
              >
                <p>Disease</p>
                <h4 v-html="category.name"></h4>
              </div>
              <div
                class="related"
                @click="() => clickFromWhere(category, false)"
              >
                <p>Related Gene</p>
                <h4>{{ renderGeneName(category.gene) }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="main-specialties" id="main-specialties">
      <div class="main-specialties__inner">
        <h1
          class="main_tit"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <span class="main_color">Top-searched</span> Specialties
        </h1>
        <div
          id="specialty__list"
          class="specialty__list"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="1000"
        >
          <div
            class="specialty__item"
            v-for="(specialty, index) in specialties"
            :key="index"
            :id="`${specialty.gene}-id`"
          >
            <div class="thum">
              <img
                :src="require(`@/assets/images/specialties/${specialty.img}`)"
                alt="specialty image"
              />
            </div>
            <div class="desc">
              <h3 class="nm" v-html="specialty.name"></h3>
              <p>{{ specialty.gene }}</p>
            </div>
          </div>
        </div>
        <div class="mobile_more">
          <button
            type="button"
            id="btn_more-specialty"
            class="btn_more"
            @click="() => clickSpecialtyMore()"
          >
            View more
          </button>
        </div>
      </div>
    </section>
    <section class="main-states">
      <div class="main-states__inner">
        <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
          <h1 class="main_tit">
            <span class="main_color">Doctagen</span> expands across U.S. states,
            transforming healthcare
            <span class="main_color">in cities nationwide</span>
          </h1>
          <p class="states_info">Top 10 States</p>
        </div>
        <div class="states__contents">
          <div
            class="states_map"
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            <div
              :id="`dot_${index + 1}`"
              class="states"
              v-for="(state, index) in states"
              :key="index"
              @click="() => activeState(index + 1)"
            >
              <button type="button" class="btn_dots"></button>
              <span>{{ state.name }}</span>
            </div>
          </div>
          <div
            class="states__list"
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            <div
              :id="`state_${index + 1}`"
              class="states__item"
              v-for="(state, index) in states"
              :key="index"
              @click="() => activeState(index + 1)"
            >
              <div class="tit">
                <p>{{ state.name }}</p>
              </div>
              <div class="city__list">
                <ul>
                  <li v-for="(city, index) in state.cityList" :key="index">
                    - {{ city }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="main-video">
      <div class="main-video__inner">
        <div
          class="desc"
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <h3>
            Doctagen Personalized<br />
            Healthcare Solutions
          </h3>
          <p>
            Doctagen, utilizing AI, connects individuals with ideal doctors
            based on genetic information. Pioneering gene-focused matching, it
            redefines how people find medical professionals
          </p>
        </div>
        <div
          class="video"
          data-aos="fade-left"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <Video :mp4="require(`@/assets/video.mp4`)" />
        </div>
      </div>
    </section>
    <section class="main-help">
      <div class="main-help__inner">
        <h1 class="main_tit">
          We are Here To <span class="main_color">Help</span>
        </h1>
        <div class="help__contents">
          <div
            class="help"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <div class="thum">
              <img src="@/assets/images/help01.png" alt="help-vision" />
            </div>
            <div class="desc">
              <h3>Vision</h3>
              <p>
                The overarching goal of Doctagen is to extend the application of
                precision medicine to all diseases. Through Doctagen.com, we aim
                to reshape the paradigm of patient care and the healthcare
                system, ushering in a new era of personalized and effective
                medical solutions
              </p>
            </div>
          </div>
          <div
            class="help"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            <div class="desc">
              <h3>Use</h3>
              <p>
                If you suspect genetic issues causing serious diseases, finding
                specialized doctors is crucial. http://doctagen.com offers a
                simple and free solution. Just input your gene name and
                location, and the platform will help you locate a suitable
                doctor. Premium services will be available in the future.
              </p>
            </div>
            <div class="thum">
              <img src="@/assets/images/help02.png" alt="help-vision" />
            </div>
          </div>
          <div
            class="help"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="1000"
          >
            <div class="thum">
              <img src="@/assets/images/help03.png" alt="help-vision" />
            </div>
            <div class="desc">
              <h3>Collaboration</h3>
              <p>
                Doctagen.com is open for any meaningful collaboration with
                doctors, hospitals, telemedicine, and genetic testing companies.
                Feel free to contact us for any business inquiry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <transition name="fade">
      <div class="dim" v-if="modalVisible">
        <Agreement v-if="contactVisible" @close="close" @submit="submit" />
        <div class="dimbg" @click="close"></div>
      </div>
      <div class="dim" v-if="isFindMyGeneModal">
        <FindMyGeneModal v-if="isFindMyGeneModal" @close="close" />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </main>
</template>

<script>
import { mapState } from "vuex";
import { fetchProfile } from "@/api/index";
import Video from "@/components/Video.vue";
import Agreement from "@/components/pop/Agreement";
import FindMyGeneModal from "@/components/pop/FindMyGeneModal";
import Cookies from "js-cookie";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();

import gtag from "ga-gtag";

export default {
  // mixins: [format],
  components: { Video, Agreement, FindMyGeneModal },
  data() {
    return {
      modalVisible: false,
      contactVisible: false,
      isFindMyGeneModal: false,
      searchWord: "",
      addressWord: "",
      visionVisible: "",
      useVisible: "",
      collaboVisible: "",
      categories: [
        {
          name: "Breast cancer",
          gene: "BRCA1 / BRCA2",
          searchTerm: "BRCA1",
        },
        {
          name: "Ovarian cancer",
          gene: "BRCA1 / BRCA2",
          searchTerm: "BRCA2",
        },
        {
          name: "Type II Diabetes",
          gene: "TCF7L2 / SLC30A8",
          searchTerm: "TCF7L2",
        },
        {
          name: "Parkinson's Disease",
          gene: "GBA",
          searchTerm: "GBA",
        },
        {
          name: "Alzheimer's Disease",
          gene: "APOE",
          searchTerm: "APOE",
        },
        {
          name: "Chronic<br> Kidney Disease",
          gene: "APOL1",
          searchTerm: "APOL1",
        },
      ],
      specialties: [
        {
          name: "Hereditary<br> Breast Cancer",
          gene: "BRCA1 / BRCA2",
          img: "specialty1.png",
        },
        {
          name: "Hereditary<br> Ovarian Cancer",
          gene: "BRCA1 / BRCA2",
          img: "specialty2.png",
        },
        {
          name: "Type 2 Diabetes",
          gene: "TCF7L2 / SLC30A8",
          img: "specialty3.png",
        },
        {
          name: "Age-Related<br> Macular Degeneration",
          gene: "ARMS2 / CFH",
          img: "specialty4.png",
        },
        {
          name: "Celiac Disease",
          gene: "HLA-DQB1 / HLA-DQA1",
          img: "specialty5.png",
        },
        {
          name: "Chronic<br> Kidney Disease",
          gene: "APOL1",
          img: "specialty6.png",
        },
        {
          name: "Familial<br> Hypercholesterolemia",
          gene: "LDLR / APOB",
          img: "specialty7.png",
        },
        {
          name: "Hereditary<br> Amyloidosis",
          gene: "TTR",
          img: "specialty8.png",
        },
        {
          name: "Hereditary<br> Hemochromatosis",
          gene: "HFE",
          img: "specialty9.png",
        },
        {
          name: "Hereditary<br> Thrombophilia",
          gene: "F2 / F5",
          img: "specialty10.png",
        },
        {
          name: "Late-Onset<br> Alzheimer's Disease",
          gene: "APOE",
          img: "specialty11.png",
        },
        {
          name: "Parkinson's Disease",
          gene: "LRRK2 / GBA",
          img: "specialty12.png",
        },
        {
          name: "MUTYH-<br> Associated Polyposis",
          gene: "MUTYH",
          img: "specialty13.png",
        },
        {
          name: "G6PD Deficiency",
          gene: "G6PD",
          img: "specialty14.png",
        },
        {
          name: "Alpha-1<br> Antitrypsin Deficiency",
          gene: "SERPINA1",
          img: "specialty15.png",
        },
      ],
      states: [
        {
          name: "California",
          cityList: ["Los Angeles", "San Diego", "San Francisco"],
        },
        {
          name: "New York",
          cityList: ["New York", "Brooklyn", "Bronx"],
        },
        {
          name: "Texas",
          cityList: ["Houston", "Dallas", "San Antonio"],
        },
        {
          name: "Florida",
          cityList: ["Miami", "Jacksonville", "Orlando"],
        },
        {
          name: "Pennsylvania",
          cityList: ["Philadelphia", "Pittsburgh", "Allentown"],
        },
        {
          name: "Illinois",
          cityList: ["Chicago", "Springfield", "Maywood"],
        },
        {
          name: "Ohio",
          cityList: ["Cleveland", "Columbus", "Cincinnati"],
        },
        {
          name: "New Jersey",
          cityList: ["Camden", "Hackensack", "Morristown"],
        },
        {
          name: "Michigan",
          cityList: ["Ann Arbor", "Detroit", "Grand Rapids"],
        },
        {
          name: "Colorado",
          cityList: ["Denver", "Aurora", "Colorado Springs"],
        },
      ],
    };
  },

  mounted() {
    this.setScreenSize();
    this.activeState(1);
  },
  computed: {
    ...mapState(["isLogin", "userId"]),
  },

  methods: {
    clickFromWhere(category, isClickFromName) {
      const term = isClickFromName ? category.name : category.gene;
      const eventName = isClickFromName
        ? `Symptom click: ${term}`
        : `Gene click: ${term}`;
      const eventCategoty = isClickFromName
        ? "Category_symptom-click"
        : "Category_gene-click";
      const eventAction = isClickFromName ? "symptom-click" : "gene-click";

      gtag("event", eventName, {
        event_category: eventCategoty,
        event_action: eventAction,
        event_label: term,
      });
    },
    renderGeneName(gene) {
      return Array.isArray(gene)
        ? gene[Math.floor(Math.random() * gene.length)]
        : gene;
    },
    selectCategory(term, isButton = false) {
      const eventName = isButton ? `Click: ${term}` : `Search: ${term}`;
      const eventCategoty = isButton
        ? "Category_button-click-event"
        : "Category_search-event";
      const eventAction = isButton ? "button-click" : "search";

      gtag("event", eventName, {
        event_category: eventCategoty,
        event_action: eventAction,
        event_label: term,
      });

      const isMultiselection = Array.isArray(term);
      let searchTerm = "";
      if (isMultiselection) {
        searchTerm = term[Math.floor(Math.random() * term.length)];
      } else {
        searchTerm = term;
      }
      this.searchWord = searchTerm;
      this.handleAgreement();
    },
    setScreenSize() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },

    handleList() {
      this.$router.push("/list");
    },
    handleAgreement() {
      //if (!(this.searchWord != "" || this.addressWord != "")) {
      if (this.searchWord === "" && this.addressWord === "") {
        return alert("Enter keyword.");
      }

      if (this.isLogin === true) {
        //console.log("true", this.userId);
        //console.log("true", this.isLogin);
        fetchProfile().then((res) => {
          if (res.data.status === 200) {
            // console.log("fetchUserTerm", res.data.data.userTerms);
            const userTerms = res.data.data.userTerms;
            if (!userTerms) {
              document.getElementById("wrap").classList.add("nonScroll");
              this.modalVisible = true;
              this.contactVisible = true;
            } else {
              this.search();
            }
          } else {
            return alert(res.data.message);
          }
        });
      } else {
        //console.log("false", this.userId);
        //console.log("false", this.isLogin);
        let agreeFlag = Cookies.get("DoctagenPersonDataCollectAgreeFlag");
        if (!agreeFlag) {
          document.getElementById("wrap").classList.add("nonScroll");
          this.modalVisible = true;
          this.contactVisible = true;
        } else {
          this.search();
        }
      }
    },
    openFindMyGeneModal() {
      document.getElementById("wrap").classList.add("nonScroll");
      this.isFindMyGeneModal = true;
    },
    search() {
      let queryWord = {};
      if (this.searchWord !== "" && this.searchWord !== undefined) {
        queryWord.searchWord = this.searchWord;
      }
      if (this.addressWord !== "" && this.addressWord !== undefined) {
        queryWord.addressWord = this.addressWord;
      }
      if (this.addressWord === "") {
        queryWord.addressWord = "san francisco";
      }
      this.$router.push({
        name: "list",
        query: queryWord,
        params: {
          pageFlag: false,
        },
      });
    },
    close() {
      this.modalVisible = false;
      this.contactVisible = false;
      this.isFindMyGeneModal = false;
      document.getElementById("wrap").classList.remove("nonScroll");
    },
    submit() {
      this.modalVisible = false;
      this.contactVisible = false;
      this.search();
      document.getElementById("wrap").classList.remove("nonScroll");
    },
    clickSpecialtyMore() {
      const specialtyList = document.getElementById("specialty__list");
      specialtyList.classList.add("more");
    },
    removeStateActive() {
      const statesDots = document.querySelectorAll(".states_map .states");
      const statesItems = document.querySelectorAll(".states__item");

      statesDots.forEach((dot) => {
        dot.classList.remove("active");
      });

      statesItems.forEach((stateItem) => {
        stateItem.classList.remove("active");
      });
    },
    activeState(idx) {
      this.removeStateActive();
      document.getElementById(`state_${idx}`).classList.add("active");
      document.getElementById(`dot_${idx}`).classList.add("active");
    },
  },
};
</script>
